<template>
    <div
        ref="sidebar"
        class="z-50 fixed inset-0 w-screen lg:flex-grow-0 lg:flex-shrink-0 lg:basis-[30rem] lg:static lg:w-auto"
        :class="{ 'sidebar-force-open': forceOpen, 'sidebar-force-close': !forceOpen, 'sidebar-loading': !isLoaded }"
    >
        <aside class="w-full h-full bg-black text-white lg:flex flex-col flex-grow p-7 lg:p-20">
            <div class="sticky top-0 lg:top-5">
                <div
                    class="-mx-7 -mt-7 lg:hidden"
                    :class="{ 'lg:!block': forceOpen }"
                >
                    <account-sidebar-toggle :white="true" />
                </div>

                <h2
                    class="text-left my-0 uppercase text-2xl"
                    v-text="$t('sidebar.headline')"
                />
                <p
                    class="text-white/80 uppercase text-sm mt-12 font-serif"
                    :class="{
                        'text-color-premium': user?.card_status === 'premium',
                        'text-color-superior': user?.card_status === 'superior',
                        'text-color-royal': user?.card_status === 'royal',
                    }"
                    v-text="$t('sidebar.status_' + (user?.card_status ?? 'premium'))"
                />
                <h3
                    class="text-white/80 text-left my-0 leading-tight !tracking-wide font-serif text-3xl"
                    :class="{
                        'text-color-premium': user?.card_status === 'premium',
                        'text-color-superior': user?.card_status === 'superior',
                        'text-color-royal': user?.card_status === 'royal',
                    }"
                >
                    {{ $t("sidebar.welcome") }}<br />
                    {{ user?.name ?? "Unbekannt" }}
                </h3>

                <p
                    class="text-sm text-white/80 mt-4"
                    :class="{
                        'text-color-premium': user?.card_status === 'premium',
                        'text-color-superior': user?.card_status === 'superior',
                        'text-color-royal': user?.card_status === 'royal',
                    }"
                    v-text="$t('sidebar.member_since', { date: memberSince })"
                />

                <nav class="mt-10">
                    <ul>
                        <li
                            v-for="item in navigation"
                            :key="item.text"
                        >
                            <nuxt-link
                                :to="localePath(item.links[0])"
                                class="nav-item cursor-pointer block uppercase py-2 px-4 my-2 border-l-[3px] border-transparent"
                                :class="{ 'nav-item-active': isActive(item) }"
                                @click="closeOnMobile"
                            >
                                {{ $t(item.text) }}
                            </nuxt-link>
                        </li>
                    </ul>
                </nav>
            </div>
        </aside>
    </div>
</template>

<script setup lang="ts">
const user = useSanctumUser<KadeweUser>();
const localePath = useLocalePath();
const sidebarStore = useSidebarStore();
const route = useRoute();
const sidebar = ref<HTMLDivElement | null>(null);
const { forceOpen } = storeToRefs(sidebarStore);
const isLoaded = ref(false);
const i18nLocale = useI18n();

interface NavigationItem {
    text: string;
    links: string[];
}

const navigation = ref<NavigationItem[]>([
    { text: "sidebar.portal", links: ["/portal/card"] },
    { text: "sidebar.status", links: ["/portal/status", "/portal/rewards"] },
    { text: "sidebar.account", links: ["/portal/account"] },
    { text: "sidebar.notifications", links: ["/portal/notifications"] },
    { text: "sidebar.events", links: ["/portal/events"] },
    { text: "sidebar.help", links: ["/portal/help"] },
    { text: "sidebar.logout", links: ["/portal/logout"] },
]);

const isActive = (item: NavigationItem) => {
    return item.links
        .map((link) => {
            return localePath(link);
        })
        .includes(route.path);
};

const locale = computed<string>(() => {
    return i18nLocale.locale.value;
});

const memberSince = computed(() => {
    if (!user.value?.create_date) {
        return "/";
    }

    return new Date(user.value?.create_date ?? "").toLocaleDateString(locale.value);
});

const closeOnMobile = () => {
    sidebarStore.closeSidebar();
};

onMounted(() => {
    window.setTimeout(() => {
        isLoaded.value = true;
    }, 200);
});
</script>

<style scoped>
.nav-item:hover,
.nav-item-active {
    @apply font-medium tracking-wide;

    /** Linear gradient from top to bottom, so that the border is only in the center 50% */
    border-image: linear-gradient(
            180deg,
            rgba(255, 255, 255, 0) 0%,
            rgba(255, 255, 255, 0) 25%,
            rgba(255, 255, 255, 1) 25%,
            rgba(255, 255, 255, 1) 75%,
            rgba(255, 255, 255, 0) 75%,
            rgba(255, 255, 255, 0) 100%
        )
        0 100%;
}

.sidebar-force-open {
    transform: translateX(-100%);
    animation: slide-in-left 0.3s forwards;
}

@keyframes slide-in-left {
    0% {
        transform: translateX(-100%);
    }

    100% {
        transform: translateX(0);
    }
}

.sidebar-force-close {
    transform: translateX(0);
    animation: slide-out-left 0.3s forwards;
}

.sidebar-force-close.sidebar-loading {
    animation-duration: 0s;
}

@screen lg {
    .sidebar-force-open,
    .sidebar-force-close {
        transform: translateX(0);
        animation: none;
    }
}

@keyframes slide-out-left {
    0% {
        transform: translateX(0);
    }

    100% {
        transform: translateX(-100%);
    }
}

.text-color-premium {
    /* */
}

.text-color-superior {
    @apply opacity-80;
    background: linear-gradient(281.38deg, #a2a2a2 5.52%, #f1f3f3 47.66%, #dfdfdf 88.7%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.text-color-royal {
    @apply opacity-80;
    background: linear-gradient(0deg, #eaada0, #eaada0),
        linear-gradient(278.52deg, #b88172 -36.7%, #f9d0c2 48.45%, #eaada0 102.94%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
</style>
